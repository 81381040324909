import Spacing from '@components/activity/src/components/Spacing';
import AppButton, { ButtonTypes } from '@components/app/src/components/Button';
import { useStarkTranslation } from '@falla/hooks/src';
import { getLanguage } from '@falla/utils/src/i18n-util';
import { useClickAway, useTitle } from 'ahooks';
import { Image, Swiper } from 'antd-mobile';
import { useHistory } from 'ice';
import React, { useEffect, useState } from 'react';

import { APP_LINK, LANGTYPE_MAPPING } from './common/constants';
import { changeLanguage } from './common/utils';
import styles from './index.module.less';

const Home = () => {
  const [language, setLanguage] = useState<string>('');
  const [langListVisible, setlangListVisible] = useState<boolean>(false);

  useEffect(() => {
    const lang = getLanguage();
    if (Object.keys(LANGTYPE_MAPPING).includes(lang)) {
      setLanguage(lang);
      changeLanguage(lang);
      // if (lang === 'ar')
    } else {
      setLanguage('en');
      changeLanguage('en');
    }
    return () => {
      console.log('Home Page unmounted');
    };
  }, []);

  const { t } = useStarkTranslation();
  const history = useHistory();
  useTitle(t('title', ''));

  const handleLangChange = (lang: string) => {
    setLanguage(lang);
    setlangListVisible(false);
    changeLanguage(lang);
  };

  // 跳转到新的第三方充值页面
  const goToRechargePage = () => {
    window.location.href = 'https://www.yigolive.com/pay/index.html#/pay';
  };

  const ref = React.useRef<HTMLDivElement>(null);
  useClickAway(() => {
    setlangListVisible(false);
  }, ref);

  return (
    <div className={styles.home}>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <div className={styles.logo}>
            <img src={require('./assets/logo_img.png')} alt={'Yigo'} />
          </div>
          <div className={styles.right}>
            <AppButton
              type={ButtonTypes.primary}
              className={styles.rechargeBtn}
              onClick={() => {
                goToRechargePage();
              }}
            >
              {t('recharge')}
            </AppButton>
            <Spacing style={{ width: '20px' }} />
            <div className={styles.lang} ref={ref}>
              <div className={styles.label} onClick={() => setlangListVisible(!langListVisible)}>
                <span>{t(LANGTYPE_MAPPING[language])}</span>
                <Spacing style={{ width: '17px' }} />
                <Image
                  style={{ transform: `rotate(${langListVisible ? 180 : 0}deg)` }}
                  src={require('./assets/arrow_down_icon.png')}
                />
              </div>
              <div className={styles.langList} style={{ visibility: langListVisible ? 'visible' : 'hidden' }}>
                {Object.keys(LANGTYPE_MAPPING).map(
                  (key) =>
                    language !== key && (
                      <div key={key} onClick={() => handleLangChange(key)}>
                        {t(LANGTYPE_MAPPING[key])}
                      </div>
                    ),
                )}
              </div>
            </div>
          </div>
        </header>
        <div className={styles.content}>
          <div className={styles.left}>
            <div className={styles.title}>{t('title1')}</div>
            <div className={styles.subTitle}>{t('title2')}</div>
            <div className={styles.download}>
              <div
                className={styles.btn}
                onClick={() => {
                  window.location.href = APP_LINK.googlePlay;
                }}
              >
                <Image
                  className={styles.googlePlayIcon}
                  width={30}
                  height={34}
                  src={require('./assets/google_play_icon.png')}
                />
                <Spacing style={{ width: '13px' }} />
                <span>Google Play</span>
              </div>
              <Spacing style={{ width: '24px' }} />
              <div
                className={styles.btn}
                onClick={() => {
                  window.location.href = `${APP_LINK.googleAPK}?v=${Date.now()}`;
                }}
              >
                <Image className={styles.apkIcon} width={21} height={36} src={require('./assets/apk_icon.png')} />
                <Spacing style={{ width: '12px' }} />
                <span>Google APK</span>
              </div>
              <Spacing style={{ width: '24px' }} />
              <div
                className={styles.btn}
                onClick={() => {
                  window.location.href = `${APP_LINK.huaweiAPK}?v=${Date.now()}`;
                }}
              >
                <Image className={styles.huaweiIcon} width={40} height={40} src={require('./assets/huawei_icon.png')} />
                <Spacing style={{ width: '10px' }} />
                <span>Huawei APK</span>
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.swiperBg} />
            <div className={styles.swiperBox}>
              <Swiper
                autoplay
                loop
                style={{
                  '--track-padding': '0 0 60px',
                  paddingTop: 14,
                }}
                defaultIndex={1}
              >
                <Swiper.Item key={1}>
                  <img className={styles.swiperImg} src={require('./assets/swiper_img_1.png')} />
                </Swiper.Item>
                {/* <Swiper.Item key={2}> */}
                {/*  <img className={styles.swiperImg} src={require('./assets/swiper_img_2.png')} /> */}
                {/* </Swiper.Item> */}
                {/* <Swiper.Item key={3}> */}
                {/*  <img className={styles.swiperImg} src={require('./assets/swiper_img_3.png')} /> */}
                {/* </Swiper.Item> */}
              </Swiper>
            </div>
          </div>
        </div>

        <footer className={styles.footer}>
          <div style={{ display: 'none' }}>
            <span
              className={styles.linkBtn}
              onClick={() => {
                history.push('/user-agreement');
              }}
            >
              {t('userAgreement')}
            </span>
            <span className={styles.divide} />
            <span
              className={styles.linkBtn}
              onClick={() => {
                history.push('/privacy-policy');
              }}
            >
              {t('privacyPolicy')}
            </span>
          </div>
          <div className={styles.copyright}>{t('copyright', '')}</div>
          <div className={styles.copyright}>{t('companyAddress', '')}</div>
          <div className={styles.copyright}>{t('contactPhone', '')}</div>
        </footer>
      </div>
    </div>
  );
};

export default React.memo(Home);
