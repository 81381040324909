/**
 * number-util.ts
 *
 * @author fukui
 */
export const parse = function (val = 0, digits = 2): number {
  val = val || 0;
  digits = digits === 0 ? 0 : digits || 2;
  return isFinite(val) ? Math.round(val * Math.pow(10, digits)) / Math.pow(10, digits) : 0;
};

/**
 * 给数字添加分隔符，默认千位分割
 * @param num
 * @param length
 * @param separator
 * @return {string}
 */
export const separator = function (num = 0, length = 3, separator = ',') {
  let numberStr = String(num.toFixed());
  const regex = new RegExp(`(\\d+)(\\d{${length}})`);
  while (regex.test(numberStr)) {
    numberStr = numberStr.replace(regex, `$1${separator}$2`);
  }
  return numberStr;
};

/**
 * 格式化千分位数字
 */
export const formatThousandSeparator = (num: number | string, separator = ',') => {
  const numParts = String(num || 0)
    .toString()
    .split('.');
  numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  return numParts.join('.');
};
