/**
 * index
 *
 * @author fukui
 */
import appBridge from '@falla/app-bridge/src';
import { getWebHost } from '@falla/utils/src/common-util';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ErrorContent from './ErrorContent';
import styles from './index.module.less';

export type ThemeTypes = 'default' | 'gray' | 'app' | 'appFull';

export enum DataErrorType {
  request = 'errorRequest',
  empty = 'errorEmpty',
}

export type AppDataErrorProps = {
  theme?: ThemeTypes;
  size?: 'normal' | 'small' | 'smallest';
  type?: DataErrorType;
  overlay?: boolean;
  onRefresh?: () => void;
  footerStyle?: React.CSSProperties;
  showCover?: boolean;
  renderCover?: React.ReactNode | (() => React.ReactNode);
} & BaseProps;

const ERROR_IMAGES = {
  errorRequest: {
    default: `${getWebHost(true)}/activity-web/upload/common/yigo-error-v2/error-request.png`,
    gray: `${getWebHost(true)}/activity-web/upload/common/yigo-error-v2/error-request.png`,
    app: `${getWebHost(true)}/activity-web/upload/common/yigo-error-v2/error-request.png`,
    appFull: `${getWebHost(true)}/activity-web/upload/common/yigo-error-v2/error-request-full-app.png`,
  },
  errorEmpty: {
    default: `${getWebHost(true)}/activity-web/upload/common/yigo-error-v2/error-empty-act.png`,
    gray: `${getWebHost(true)}/activity-web/upload/common/yigo-error-v2/error-empty-act.png`,
    app: `${getWebHost(true)}/activity-web/upload/common/yigo-error-v2/error-empty-app.png`,
    appFull: `${getWebHost(true)}/activity-web/upload/common/yigo-error-v2/error-empty-full-app.png`,
  },
};

const FOLO_ERROR_IMAGES = {
  errorRequest: {
    default: `${getWebHost(true)}/activity-web/upload/common/folo-error-v2/error-request.png`,
    gray: `${getWebHost(true)}/activity-web/upload/common/folo-error-v2/error-request.png`,
    app: `${getWebHost(true)}/activity-web/upload/common/folo-error-v2/error-request.png`,
    appFull: `${getWebHost(true)}/activity-web/upload/common/folo-error-v2/error-request-full-app.png`,
  },
  errorEmpty: {
    default: `${getWebHost(true)}/activity-web/upload/common/folo-error-v2/error-empty-act.png`,
    gray: `${getWebHost(true)}/activity-web/upload/common/folo-error-v2/error-empty-act.png`,
    app: `${getWebHost(true)}/activity-web/upload/common/folo-error-v2/error-empty-app.png`,
    appFull: `${getWebHost(true)}/activity-web/upload/common/folo-error-v2/error-empty-full-app.png`,
  },
};

const getImage = (props: AppDataErrorProps) => {
  const { type, theme } = props;
  const defaultTypeImage = appBridge.isFoloApp()
    ? FOLO_ERROR_IMAGES[DataErrorType.empty]
    : ERROR_IMAGES[DataErrorType.empty];
  const _image = appBridge.isFoloApp() ? FOLO_ERROR_IMAGES[type][theme] : ERROR_IMAGES[type][theme];
  const image = _image || defaultTypeImage[theme] || defaultTypeImage.default;
  return image;
};

export const AppDataError = (props: AppDataErrorProps) => {
  const { showCover = true, renderCover } = props;

  const image = getImage(props);
  const { t } = useTranslation();

  // 自定义缺省图
  const CustomCover = typeof renderCover === 'function' ? renderCover() : renderCover;

  return (
    <div
      className={classnames(props.className, styles.dataError, {
        [styles.isNormal]: props.size === 'normal',
        [styles.isSmall]: props.size === 'small',
        [styles.isSmallest]: props.size === 'smallest',
        [styles.isAppFullLarge]: props.theme === 'appFull',
        [styles.isOverlay]: !!props.overlay,
        [styles.isNotCover]: !props.showCover,
      })}
      style={props.style}
    >
      <div className={styles.dataErrorContainer}>
        {showCover && (
          <>
            {renderCover && <>{CustomCover}</>}
            {!renderCover && (
              <div className={styles.dataErrorCover}>
                <img src={image} />
              </div>
            )}
          </>
        )}

        <div className={styles.dataErrorFoot} style={props.footerStyle}>
          <ErrorContent {...props}>{props.children && props.children}</ErrorContent>
        </div>
      </div>
    </div>
  );
};

AppDataError.defaultProps = {
  theme: 'default',
  size: 'normal',
  type: DataErrorType.empty,
};

export default AppDataError;
