import './index.less';

import Toast from 'antd-mobile/es/components/toast';
import { ToastShowProps } from 'antd-mobile/es/components/toast/methods';

const { show } = Toast;

Toast.show = (args: ToastShowProps | string) => {
  Toast.clear();
  return show(args);
};

// @ts-ignore
window._fallaMicro_toast = (window?._fallaMicro_toast as typeof Toast) || Toast;
// @ts-ignore
const _Toast: typeof Toast = window._fallaMicro_toast;

export { _Toast as Toast };
export default _Toast;
